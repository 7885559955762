<template>
  <v-main>

    <v-container
        id="invoice-container"
        fluid
        tag="section"
    >

      <base-material-card
          icon="mdi-text-box-plus-outline"
          class="px-5 py-3"
          ref="form"
      >

        <div>
          <v-alert
              type="error"
              outlined
              v-for="(error, i) in errors"
              :key="i"
          >
            {{ error }}
          </v-alert>
        </div>

        <v-form ref="invoiceForm"
                v-model="valid"
                @submit.prevent="validate"
                lazy-validation
        >
          <v-row>
            <v-col
                cols="12"
                sm="6"
            >
              <v-select
                  v-model="invoiceType"
                  label="Invoice Type"
                  :items="invoiceTypes"
                  :rules="rules.required"
              ></v-select>
            </v-col>
          </v-row>

          <v-row v-if="computedSelectUser">
            <v-col
                cols="12"
                sm="6"
            >
              <v-select
                  v-model="computedUser"
                  label="User"
                  :items="userList"
                  item-text="name"
                  item-value="id"
                  return-object
                  :rules="rules.required"
              ></v-select>
            </v-col>
          </v-row>

          <v-row v-if="computedSelectClaim">
            <v-col
                cols="12"
                sm="6"
            >
              <v-select
                  v-model="computedClaim"
                  label="Claim"
                  :items="claimList"
                  item-text="name"
                  item-value="id"
                  return-object
                  :rules="rules.required"
              ></v-select>
            </v-col>
          </v-row>


          <v-row>
            <v-col
                cols="6"
                sm="6"
            >
              <v-text-field
                  v-model="invoice.amount"
                  label="Amount(s)"
                  :rules="rules.currency"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col
                cols="12"
                sm="9"
            >
              <v-text-field
                  v-model="invoice.description"
                  label="Description"
                  type="text"
                  :rules="rules.required"
              ></v-text-field>
            </v-col>

          </v-row>

          <v-row>
            <v-col
                cols="12"
                sm="3"
            >
              <v-text-field
                  v-model="computedInvoiceDate"
                  label="Invoice Date"
                  type="date"
                  :rules="rules.date"
              ></v-text-field>
            </v-col>

          </v-row>

          <v-row>
            <v-col
                cols="12"
                sm="3"
            >
              <v-text-field
                  v-model="computedDueDate"
                  label="Due Date"
                  type="date"
                  :rules="rules.date"
              ></v-text-field>
            </v-col>

          </v-row>


          <v-row>
            <v-col
                cols="6"
                sm="4"
            >
              <div class="my-2">
                <v-btn
                    :disabled="processing"
                    :loading="processing"
                    color="primary"
                    dark
                    type="submit"
                >
                  Submit
                </v-btn>
              </div>
            </v-col>
          </v-row>

        </v-form>


      </base-material-card>

      <div class="text-center">
        <v-dialog
          v-model="processing"
          hide-overlay
          persistent
          width="400"
        >
          <v-card class="text-center">
            <v-card-text class="text-center">
              <v-row>
                <v-col cols="12">
                  <p class="text-center">Generating invoice(s), please stand by</p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-progress-circular
                      :size="70"
                      :width="10"
                      indeterminate
                      color="primary"
                  ></v-progress-circular>
                </v-col>
              </v-row>
            </v-card-text>

          </v-card>

        </v-dialog>
        </div>


    </v-container>

  </v-main>
</template>

<script>
import api from '@/services/api'
import validation from "@/utils/validation";
import geoUtils from "@/utils/geography";
import moment from "moment";
import constants from "@/utils/constants";

export default {
  name: 'CoreInvoiceView',

  created() {
  },

  computed: {
    computedClaim: {
      get() {
        if (this.invoice.claim == undefined) {
          return
        }
        return this.claimList.find(claim => this.invoice.claim.id === claim.id)
      },
      set(value) {
        this.invoice.claim = value
      }
    },
    computedUser: {
      get() {
        if (this.user == undefined) {
          return
        }
        return this.userList.find(user => this.user.id === user.id)
      },
      set(value) {
        this.user = value
      }
    },
    claimList: {
      get() {
        let list = []
        this.claims.forEach(claim => {
          if (claim.status !== constants.CLAIM_STATUS_APPROVED) {
            return
          }
          let item = {
            id: claim.id,
            name: `${claim.user.firstName} ${claim.user.lastName} - ${claim.dependent.firstName} ${claim.dependent.lastName} (${moment(claim.incidentDate).format('MM/DD/YYYY')})`
          }
          list.push(item)
        })
        return list
      }
    },
    userList: {
      get() {
        let list = []
        this.users.forEach(user => {
          let item = {
            id: user.id,
            name: user.names
          }
          list.push(item)
        })
        return list
      }
    },
    computedInvoiceDate: {
      get() {
        if (this.invoice.invoiceDate == null) {
          return
        }
        return moment(this.invoice.invoiceDate, 'YYYY-MM-DD').format('YYYY-MM-DD')
      },
      set(value) {
        this.invoice.invoiceDate = value
      }
    },
    computedDueDate: {
      get() {
        if (this.invoice.dueDate == null) {
          return
        }
        return moment(this.invoice.dueDate, 'YYYY-MM-DD').format('YYYY-MM-DD')
      },
      set(value) {
        this.invoice.dueDate = value
      }
    },
    computedSelectClaim: {
      get() {
        return (this.invoiceType === constants.INVOICE_TYPE_REPLENISHMENT)
      }
    },
    computedSelectUser: {
      get() {
        return (this.invoiceType === constants.INVOICE_TYPE_REGISTRATION)
      }
    }
  },

  watch: {
    errors: function () {
      scroll(0, 0)
    },
    invoiceType: function (val) {
      this.invoice.description = val
      if (val === constants.INVOICE_TYPE_REPLENISHMENT && this.claimList.length === 0) {
        this.fetchClaims()
      } else if(val === constants.INVOICE_TYPE_REGISTRATION && this.userList.length === 0) {
        this.fetchUsers()
      }
    },
    computedClaim: function(val) {
      this.invoice.description = ''
      const claim = this.claims.find(item => (val.id == item.id))
      if (claim !== undefined) {
        this.invoice.description = `Replenishment for ${claim.user.names}`
      }
    }
  },

  methods: {
    validate() {
      if(this.$refs.invoiceForm.validate()) {
        if (this.invoiceType === constants.INVOICE_TYPE_REGISTRATION) {
          this.createInvoice()
        } else {
          this.createBulkInvoices()
        }
      }
    },
    fetchClaims() {
      api
          .fetchClaims()
          .then(response => {
            this.claims = response.data
          })
          .catch(err => {
            this.handleError(err)
          })
    },
    fetchUsers() {
      api
          .fetchUsers()
          .then(response => {
            this.users = response.data
          })
          .catch(err => {
            this.handleError(err)
          })
    },
    createBulkInvoices() {
      this.processing = true
      const payload = {
        description: this.invoice.description,
        amount: this.invoice.amount,
        dueDate: this.invoice.dueDate,
        invoiceDate: this.invoice.invoiceDate,
      }
      if (this.invoice.claim !== null && this.invoice.claim.id != undefined) {
        payload.claimId = this.invoice.claim.id
      }

      api
          .createBulkInvoices(payload)
          .then(response => {
            this.processing = false
            if (response.status === 201) {
              if (payload.claimId !== undefined) {
                const claim = this.claims.find(item => item.id === payload.claimId)
                this.$router.push(`/member/${claim.user.id}/claim/${claim.id}/view`)
              } else {
                this.$router.push(`/invoices/status`)
              }
            }
          })
          .catch(err => {
            this.handleError(err)
          })
    },
    createInvoice() {
      this.processing = true
      const payload = {
        description: this.invoice.description,
        amount: this.invoice.amount,
        dueDate: this.invoice.dueDate,
        invoiceDate: this.invoice.invoiceDate,
      }

      api
          .createInvoice(this.user.id, payload)
          .then(response => {
            this.processing = false
            if (response.status === 201) {
              this.$router.push(`/member/${this.user.id}/view`)
            }
          })
          .catch(err => {
            this.handleError(err)
          })
    },
    handleError(err) {
      let errorMessage = api.parseResponseError(err)
      this.errors.push(errorMessage)
    }
  },

  data() {
    return {
      valid: true,
      errors: [],
      invoice: {
        claim: null,
        user: null,
        amount: null,
        description: null,
        invoiceDate: null,
        dueDate: null
      },
      claim: {
        id: null,
        user: {}
      },
      claims: [],
      users: [],
      user: {},
      invoiceType: null,
      selectClaim: false,
      invoiceTypes: constants.getInvoiceTypes(),
      processing: false,
      rules: {
        required: [
          v => validation.isRequired(v) || 'required'
        ],
        currency: [
          v => validation.isCurrency(v) || 'must be in currency format'
        ],
        date: [
            v => validation.isDate(v) || 'must be a valid date in the format mm/dd/yyyy'
        ]
      }
    }
  }
}
</script>